:root {
    --font-primery: 'Gilroy', 'Arial', sans-serif;
    --font-secondary: 'Exo 2', 'Arial', sans-serif;
    --color-white: #ffffff;
    --color-white-rgba40: rgba(255, 255, 255, 0.4);
    --color-black: #242628;
    --color-blue: #5695f2;
    --color-gray: #f3f3f3;
    --color-gray-2: #9fa5af;
    --color-gray-3: #dcdfe3;
    --color-red-1: #e64646;
    --gradient-primary: linear-gradient(180deg, #88b8ff 3.56%, #5695f2 100%), linear-gradient(0deg, #f8f9fb, #f8f9fb), #ffffff;
    --gradient-secondary: linear-gradient(180deg, #88b8ff 0%, #5695f2 100%);
    --banner-gradient: linear-gradient(180deg, #ebf0f7 0%, rgba(255, 255, 255, 0) 100%);
}
