@font-face {
    font-family: 'Gilroy';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2'), url('../fonts/Gilroy-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/Gilroy-Bold.woff2') format('woff2'), url('../fonts/Gilroy-Bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/Exo2-Light.woff2') format('woff2'), url('../fonts/Exo2-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Exo2-Regular.woff2') format('woff2'), url('../fonts/Exo2-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/Exo2-Medium.woff2') format('woff2'), url('../fonts/Exo2-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    font-weight: 500;
    font-style: italic;
    src: url('../fonts/Exo2-MediumItalic.woff2') format('woff2'), url('../fonts/Exo2-MediumItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/Exo2-Bold.woff2') format('woff2'), url('../fonts/Exo2-Bold.woff') format('woff');
    font-display: swap;
}
