@import '../../../assets/global-style/mixin';

.navigation {
    position: relative;
    padding-top: 12px;

    &__flex {
        display: flex;
        align-items: center;
    }

    &__el {
        &--logo {
            margin-right: 44px;

            @include before_1024 {
                margin-right: 24px;
            }
        }

        &--menu {
            flex-grow: 1;
        }

        &--mobile {
            display: none;

            @include before_768 {
                display: block;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
    }

    &__logo-link {
        margin-right: 16px;

        @include before_768 {
            width: 30px;
            height: auto;
        }
    }

    &__logo-text {
        max-width: 140px;
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;

        @include before_768 {
            font-size: 16px;
            line-height: 100%;
        }
    }
}
